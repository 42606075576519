<template>
    <div class="popup">
        <div class="box">
            <div class="popTitle popTitleArea">
                <p><i class="micon">info</i>분석 정보 확인</p>
            </div>
            <div class="popupContArea">
                <ul class="cont">
                    <li>
                        <div class="titArea">
                            <p class="tit">환자 정보</p>
                        </div>
                        <ul class="contArea">
                            <li>
                                <p>환자번호</p>
                                <span>{{ targetInfo.patientSn }}</span>
                            </li>
                            <li>
                                <p>이름</p>
                                <span>{{ targetInfo.name }}</span>
                            </li>
                            <li>
                                <p>생년월일</p>
                                <span>{{ targetInfo.birth }}</span>
                            </li>
                            <li>
                                <p>성별</p>
                                <span>{{ targetInfo.sex === 0 ? '남' : targetInfo.sex === 1 ? '여' : '' }}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <div class="titArea">
                            <p class="tit">분석 정보</p>
                        </div>
                        <ul class="contArea">
                            <li>
                                <p>분석 유형</p>
                                <span>{{ getXrayType() }}</span>
                            </li>
                            <li class="noticeText">
                                <p></p><em>※Whole Spine 정면 이미지만 분석 가능</em>
                            </li>
                            <li>
                                <p>촬영일</p>
                                <span>{{ pictureDt }}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="textArea">
                    <p v-if="!targetInfo.no">이 환자는 등록되지 않은 환자입니다.</p>
                    <p>해당 환자로 분석하시겠습니까?</p>
                </div>
                <div class="btnW popBtn">
                    <button class="btn default" @click="handleClose" v-ripple>{{ CONST_CLOSE }}</button>
                    <button class="btn solid" @click="registPatient()" v-ripple>분석</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '../plugins/EventBus';
import API_REGIST_PATIENT from '../API/patient/regist';

export default {
    components: {},
    props: {},
    data() {
        return {
            targetInfo: {},
            pictureDt: localStorage.getItem('pictureDt') ?? '',
            uploadedXrayType: []
        };
    },
    watch: {},
    created() {},
    mounted() {
        this.setTargetInfo();
        this.setXrayType();
    },
    destroyed() {},
    methods: {
        setTargetInfo() {
            const targetInfo = JSON.parse(localStorage.getItem("targetInfo"));
            if (targetInfo) {
                this.targetInfo = targetInfo;
            } else {
                this.targetInfo = {};
            }
        },
        setXrayType() {
            const xrayType = JSON.parse(localStorage.getItem("uploadedXrayType"));
            if (xrayType) {
                this.uploadedXrayType = xrayType;
            } else {
                this.uploadedXrayType = [];
            }
        },
        getXrayType() {
            if (this.uploadedXrayType.includes(this.XRAY_TYPE_SPINE) && this.uploadedXrayType.includes(this.XRAY_TYPE_HANDS)) {
                return '통합분석(척추, 수부)';
            } else if (this.uploadedXrayType.includes(this.XRAY_TYPE_SPINE)) {
                return 'Cobb\'s angle & Risser grade'; // spine
            } else if (this.uploadedXrayType.includes(this.XRAY_TYPE_HANDS)) {
                return '수부';
            } else {
                return '올바른 X-ray 유형이 아닙니다.';
            }
        },
        async registPatient() {
            if (!this.targetInfo.no) { // 신규등록인 경우
                if (!this.targetInfo.birth) this.targetInfo.birth = "1901-01-01"; // 테스트용 임시 생일
                let res = await API_REGIST_PATIENT.request(this.targetInfo);
                if (res.isSuccess) {
                    localStorage.setItem('targetInfo', JSON.stringify(res.data));
                    this.$emit('goReading');
                } else {
                    this.showPopup(res.errorMsg, res.status);
                }
            } else {
                this.$emit('goReading');
                localStorage.removeItem("uploadedXrayType");
            }
        },
        handleClose() {
            EventBus.emit('closePopup');
            EventBus.emit('resetImage');
            this.$emit('closePopup');
        }
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.popup {
    .box {
        width: 580px;
        .x { position: static; }
        .popTitle {
            display: flex; justify-content: space-between; align-items: center;
            p {
                display: flex; justify-content: flex-start; align-items: center;
                i { margin-right: 8px; }
            }
        }
        .popupContArea {
            padding: 24px;
            max-height: 800px;
            overflow: auto;
            .cont {
                padding: 0;
                ul.contArea {
                    padding-left: 0;
                    margin-bottom: 12px;
                    li {
                        display: flex; align-items: center; padding: 0 0 4px 8px; font-size: 14px;
                        p { width: 30%; }
                        span { width: 70%; padding: 0 10px; background: #fbfbfb; border: 1px solid #e5e5e5; border-radius: 4px; height: 30px; line-height: 30px; color: #111 !important; text-align: center; }
                        &.noticeText {
                            margin-bottom: 5px;
                            em { display: block; font-size: 12px; color: var(--color-point); }
                        }
                    }
                }
            }
            .textArea {
                margin-bottom: 24px;
                p { text-align: center; font-size: 15px; line-height: 2rem; font-weight: bold; }
            }
            .popBtn {
                padding-bottom: 0;
            }
        }
    }
}
</style>
